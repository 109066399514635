import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ForcedLogOutAuthAction } from "../../redux/actions/AuthAction";
import { Redirect } from 'react-router'

function Registropago(props) {

    let history = useHistory();

    const { logout } = props


    const [metodo, setMetodo] = useState("")
    const [idCobro, setIdCobro] = useState("")
    const [redirect, setRedirect] = useState("")
    const [metodoValido, setMetodoValido] = useState(false)
    const [idcobroValido, setIdCobroValido] = useState(false)
    const [datosOk, setDatosOk] = useState(false)
    const [isThereError, setisThereError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [cargando, setCargando] = useState(false)



    const setError = (mensaje) => {
        setisThereError(true)
        setErrorMessage(mensaje)
    }

    const unsetError = () => {
        setisThereError(false)
        setErrorMessage("")
    }




    const handleChangeMetodo = (e) => {

        var result = /^[A-Za-z\s]{1,50}$/.test(e.target.value);
        setMetodo(e.target.value)
        if (result) {
            setMetodoValido(true)
        } else {
            setMetodoValido(false)
        }

        if (result && idcobroValido) {
            setDatosOk(true)
        } else {
            setDatosOk(false)
        }

    }


    const handleChangeIdCobro = (e) => {
        var result = /^[A-Za-z0-9\-]{1,100}$/.test(e.target.value);
        setIdCobro(e.target.value)
        if (result) {
            setIdCobroValido(true)
        } else {
            setIdCobroValido(false)
        }

        if (result && metodoValido) {
            setDatosOk(true)
        } else {
            setDatosOk(false)
        }
    }

    const handleRegistrarPago = () => {
        // const redireccion = "/buscarturno?id_turno=" + id
        // setRedirect(redireccion)
        // window.location.reload();
        const registrarPago = async () => {

            unsetError()
            setCargando(true)

            let url = 'https://rtosmm.reviturnos.com.ar/api/auth/regPag'

            const { user } = JSON.parse(localStorage.getItem("auth"))
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.access_token
            }

            const datos = {
                id_turno: props.id,
                metodo_pago: metodo,
                id_pago: idCobro
            }

            console.log(datos);

            fetch(url, { method: 'POST', headers, body: JSON.stringify(datos) })
                .then(async res => {

                    if (res.status === 200) {
                        const respuesta = await res.json();

                        if (respuesta) {


                            console.log(respuesta)
                            setCargando(false)
                            unsetError()
                            const redireccion = "/buscarturno?id_turno=" + props.id + "&refresh=refresh"
                            setRedirect(redireccion)
                        }


                    } else {
                        if (res.status === 500) {
                            logout(history)
                        }
                        const error = await res.json();
                        setCargando(false)
                        setError(error.mensaje)

                    }

                })
                .catch((error) => {
                    setCargando(false)
                    setError(error.message)
                })
        }
        registrarPago()

    }

    return (
        <div className="card p-4" style={{ width: "400px" }}>

            <h4>Ingrese datos del pago:</h4>

            <div className="form-group">
                <label htmlFor="input-metodo">Metodo</label>
                <input value={metodo} onChange={handleChangeMetodo} type="text" className="form-control" id="input-metodo" />
            </div>
            <div className="form-group">
                <label htmlFor="input-idCobro">Id de cobro</label>
                <input value={idCobro} onChange={handleChangeIdCobro} type="text" className="form-control" id="input-idCobro" />
            </div>

            {datosOk && <button onClick={handleRegistrarPago} className="btn btn-dark">Registrar</button>}

            {
                cargando && <div style={{ width: "100%", textAlign: "center", marginTop: "50px" }}>


                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }

            {
                isThereError && <>
                    <div style={{ position: "relative" }} className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div></>
            }

            { redirect && <Redirect to={redirect} />}


        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (history) => {
            dispatch(ForcedLogOutAuthAction(history));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registropago);
